import React, { useEffect } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import useShoppingCart from "../hooks/use-shopping-cart"
import AccentedButton from "../components/accented-button"

const HomeButtonContainer = styled.div`
  text-align: center;
  margin-top: 12px;
`

export default function PurchaseSuccessfulPage(props) {

  // Assumption:
  // The only reason someone would come to this page
  // is that they've completed a purchase
  const cart = useShoppingCart()
  useEffect( () => {
    if (cart.getQuantity() > 0) {
      cart.clear()
    }
  }, [cart])

  return (
    <div role="main">
      <h2>Purchase successful</h2>
      <p>Thank you for your purchase!</p>
      <p>You will receive a receipt through the email you provided.</p>
      <HomeButtonContainer>
        <AccentedButton
          onClick={() => navigate("/")}
          data-qa={"HomeButton"}
        >
          Homepage
        </AccentedButton>
      </HomeButtonContainer>
    </div>
  )
}
